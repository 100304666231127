<template>
  <div class="product-report">
    <b-card class="page-card">
      <div slot="header">
        <b-row>
          <b-col cols="12">
            <div class="page-header-left">
              <div class="pr-1 header-icon">
                <i class="cuis-gem card-icon-font"></i>
              </div>
              <div>
                <h5 class="mb-0">
                  <b>FIN Performographics</b>
                </h5>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-if="!banks">
        <content-placeholders>
          <content-placeholders-text :lines="2"></content-placeholders-text>
        </content-placeholders>
        <hr />
        <content-placeholders>
          <content-placeholders-img
            class="table-holder"
          ></content-placeholders-img>
        </content-placeholders>
      </div>
      <div v-else>
        <div class="page-sub-title">
          <div>
            <h5>
              {{ performographic.product_name }} -
              {{ performographic.theme_name }}
            </h5>
          </div>
          <div>
            <span class="title-icon">
              <i class="fa fa-university fa-3x"></i>
            </span>
            <div class="inline-display">
              <v-wait for="applyingFilter">
                <template slot="waiting">
                  <i class="fa fa-spinner fa-spin"></i>
                </template>
                <h4 class="mb-0" v-if="resultCount === 0">
                  <b>0</b>
                </h4>
                <h4 class="mb-0" v-else>
                  <b>{{ resultCount | numberFormat }}</b>
                </h4>
              </v-wait>
              <h6>Matching {{ resultCount | pluralize('Institution') }}</h6>
            </div>
          </div>
        </div>
        <hr class="header-divide" />
        <div>
          <b-row>
            <b-col cols="12">
              <b-table
                striped
                hover
                responsive="md"
                :items="banks"
                :fields="fields"
              >
                <template v-slot:cell(city)="data">{{
                  data.value.toLowerCase() | titleCase
                }}</template>
                <template v-slot:cell(is_bank)="data">{{
                  data.value ? 'Bank' : 'Credit Union'
                }}</template>
                <template v-slot:cell(assets)="data">{{
                  data.value | numberFormat
                }}</template>
                <template
                  v-slot:cell(table-caption)="data"
                  v-if="this.total === 0"
                >
                  <div class="text-center">
                    <i>No data found ...</i>
                  </div>
                </template>
              </b-table>
              <BlockUI :blockMsg="blockMsg" v-if="blockUI"></BlockUI>
            </b-col>
          </b-row>
          <div v-if="this.total !== 0">
            <br />
            <b-card-group class="bottom-deck" deck>
              <b-card
                :class="[
                  'purchase-card alert alert-info',
                  { 'hide-with-space': emptyResultSet }
                ]"
              >
                <v-wait for="updatingPriceDetails">
                  <template slot="waiting">
                    <content-placeholders-heading />
                    <content-placeholders-text
                      :lines="7"
                    ></content-placeholders-text>
                  </template>
                  <b-row>
                    <b-col cols="2">
                      <span class="title-icon">
                        <i class="fa fa-download fa-4x"></i>
                      </span>
                    </b-col>
                    <b-col cols="5">
                      <div>
                        <h2 class="mb-0">
                          <b>${{ totalDollarAmount | numberFormat }}</b>
                          <small>
                            <sup>{{ totalCents }}</sup>
                          </small>
                        </h2>
                      </div>
                      <div>Buy &amp; Download Now</div>
                    </b-col>
                    <b-col cols="5" style="width: 10.5em;color: white;">
                      <button
                        v-b-modal
                        class="btn btn-success"
                        @click="handlePurchase()"
                        :disabled="disablePurchase"
                      >
                        Purchase Now
                      </button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="2"></b-col>
                    <b-col cols="5">
                      <b-row class="price-box">
                        <table class="table-borderless">
                          <thead>
                            <tr>
                              <th>Price Details</th>
                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Number of FIs</td>
                              <td class="text-right">{{ resultCount }}</td>
                              <td></td>
                            </tr>
                            <tr v-if="isMinimumOrder">
                              <td class="text-no-wrap pr-4">Minimum Order</td>
                              <td class="text-right">$500.00</td>
                              <td></td>
                            </tr>
                            <tr v-if="!isMinimumOrder">
                              <td>Cost / FI</td>
                              <td class="text-right">${{ pricePerFI }}</td>
                              <td></td>
                            </tr>
                            <tr v-if="!isMinimumOrder">
                              <td>Sub - Total</td>
                              <td class="text-right">
                                ${{ priceWithoutDiscounts.toFixed(2) }}
                              </td>
                              <td></td>
                            </tr>
                            <tr v-if="tokens !== 0">
                              <td>Tokens Used</td>
                              <td class="text-right">{{ tokensBeingUsed }}</td>
                              <td class="text-no-wrap">
                                of {{ tokens }} total
                              </td>
                            </tr>
                            <tr class="strong">
                              <td>Total Price</td>
                              <td class="text-right">
                                ${{ parseFloat(totalAmount).toFixed(2) }}
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </b-row>
                    </b-col>
                  </b-row>
                </v-wait>
              </b-card>
              <b-card class="std-border mb-0 firmographics-filter">
                <b-form @submit="onSubmit" @reset="onReset">
                  <b-row>
                    <b-col cols="7">
                      <h4 class="title-item mb-0">
                        <b>
                          <i class="fa fa-filter fa-lg"></i>
                          <span id="filterInfo">
                            Firmographics Filter
                            <i class="icon-info icons small-line-icon"></i>
                          </span>
                        </b>
                      </h4>
                      <b-popover :target="'filterInfo'" triggers="hover focus"
                        >Identify your competitive sweet spot of financial
                        institutions at the intersection of firmographics,
                        technographics and performographics. This unprecedented
                        combination of traditional FI characteristics,
                        technologies deployed (or absent) and performance
                        challenges enhances your account-based marketing (ABM)
                        by identifying those institutions where your messaging
                        will resonate.</b-popover
                      >
                      <span class="filter-help"
                        >Use filters below to focus your list</span
                      >
                    </b-col>
                    <b-col cols="5 text-right">
                      <b-button type="reset" variant="secondary" class="mr-1"
                        >Reset</b-button
                      >
                      <b-button type="submit" variant="primary"
                        >Apply Filter</b-button
                      >
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col sm="4" class="total_asset_label"
                      >Total Assets(000s):</b-col
                    >
                    <b-col sm="4">
                      <b-form-group>
                        <b-form-input
                          type="number"
                          id="asset-min"
                          v-model="form.assetMin"
                          placeholder="Min"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="4">
                      <b-form-group>
                        <b-form-input
                          type="number"
                          id="asset-max"
                          v-model="form.assetMax"
                          placeholder="Max"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="4">FI Type:</b-col>
                    <b-col sm="8">
                      <b-form-group>
                        <b-row>
                          <b-col cols="12">
                            <b-form-checkbox-group
                              id="fi_types"
                              v-model="form.fiType"
                              name="fi_types"
                              :options="{ bank: 'Banks', cu: 'Credit Unions' }"
                            ></b-form-checkbox-group>
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="4" class="geography_label">Geography:</b-col>
                    <b-col sm="8">
                      <multiselect
                        class="simple-select"
                        :show-labels="false"
                        v-model="form.geography"
                        placeholder="Select State"
                        :options="states"
                        :multiple="true"
                      ></multiselect>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card>
            </b-card-group>
          </div>
        </div>
      </div>
    </b-card>
    <OrderDetails
      :stage="orderStage"
      :clientToken="clientToken"
      :downloadUrl="downloadUrl"
      :show="showModal"
      :totalRecords="filterResult"
      :amount="totalAmount"
      :loading="loading"
      :tokens="tokens"
      :tokensBeingUsed="tokensBeingUsed"
      @pay="payForOrder"
      @closeModal="closeModal"
      @createOrder="createOrder"
    ></OrderDetails>
    <UserAuthenticationModal
      :show="showAuthModal"
      @closeAuthModal="closeAuthModal"
      @loginSuccess="loginSuccess"
    ></UserAuthenticationModal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapGetters, mapState } from 'vuex'
import { BTErrorMessage } from '@/utilities'
import UserAuthenticationModal from '@/modules/core/components/UserAuthenticationModal'

const OrderDetails = () => import('@/views/technographics/OrderDetails')
const BlockUI = () => import('@/views/technographics/BlockUI')

export default {
  components: {
    OrderDetails,
    BlockUI,
    Multiselect,
    UserAuthenticationModal
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.productId = vm.$route.query.product_id
      vm.themeId = vm.$route.query.theme_id
      vm.$http
        .get('api/performographics', {
          params: { product_id: vm.productId, theme_id: vm.themeId },
          handleErrors: true
        })
        .then(response => {
          vm.banks = response.data.banks
          vm.performographic = response.data.performographic
          vm.total = response.data.total
          vm.tokens = response.data.tokens
          vm.filterResult = response.data.total
          vm.totalAmount = response.data.total_price
          vm.encryptedPrice = response.data.encrypted_price
          vm.minimumPrice = response.data.minimum_price
          vm.priceWithoutDiscounts = response.data.price_without_discounts
          document.title = 'FIN Performographics'
          if (!vm.skipFilter()) {
            vm.blockUI = true
            vm.setFilterData()
            vm.submitFilter()
          }
        })
    })
  },
  data() {
    return {
      form: {
        assetMin: null,
        assetMax: null,
        fiType: ['bank', 'cu'],
        geography: []
      },
      totalAmount: null,
      encryptedPrice: null,
      productId: null,
      themeId: null,
      blockUI: false,
      performographic: {},
      banks: null,
      tokens: 0,
      priceWithoutDiscounts: 0,
      minimumPrice: 0,
      total: 0,
      filterResult: null,
      fields: [
        { key: 'name', sortable: false, label: 'Name' },
        {
          key: 'address',
          sortable: false,
          label: 'Street Address / Main Office'
        },
        { key: 'city', sortable: false, label: 'City' },
        { key: 'state', sortable: false, label: 'State' },
        { key: 'is_bank', sortable: false, label: 'FI Type' },
        {
          key: 'assets',
          sortable: false,
          label: 'Assets',
          class: 'text-right'
        },
        {
          key: 'branches',
          sortable: false,
          label: 'Branches',
          class: 'text-right'
        },
        { key: 'domain', sortable: false, label: 'Domain' }
      ],
      showModal: false,
      orderId: null,
      clientToken: null,
      orderStage: 'summary',
      loading: false,
      downloadUrl: null,
      fiTypes: {},
      showAuthModal: false
    }
  },
  computed: {
    ...mapGetters('Authentication', ['currentUserEmail']),
    ...mapGetters('Ability', ['canPurchase']),
    ...mapState('marketShareReport', ['states']),
    disablePurchase() {
      if (!this.canPurchase) {
        return true
      }
      return this.total <= 5 || this.resultCount <= 0
    },
    emptyResultSet() {
      return this.resultCount <= 0
    },
    resultCount: function() {
      return this.filterResult !== null ? this.filterResult : this.total
    },
    blockMsg() {
      let noRecord = 'No records found.'
      let noFilter = 'Filter details are available on purchase.'
      return this.resultCount > 0 ? noFilter : noRecord
    },
    totalDollarAmount() {
      return parseInt(this.totalAmount)
    },
    totalCents() {
      let formattedAmount = new Intl.NumberFormat('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(this.totalAmount)
      return formattedAmount.split('.')[1]
    },
    pricePerFI() {
      return (this.priceWithoutDiscounts / this.resultCount).toFixed(2)
    },
    tokensBeingUsed() {
      if (this.priceWithoutDiscounts == 0 && this.minimumPrice == 0) {
        return 0
      } else if (this.priceWithoutDiscounts < this.minimumPrice) {
        return Math.min(this.minimumPrice, this.tokens)
      } else if (this.priceWithoutDiscounts > this.minimumPrice) {
        return Math.min(this.tokens, this.priceWithoutDiscounts)
      } else {
        return this.tokens
      }
    },
    isMinimumOrder() {
      return this.priceWithoutDiscounts < this.minimumPrice
    }
  },
  methods: {
    submitFilter() {
      this.$wait.start('applyingFilter')
      this.orderStage = 'summary'
      let data = {
        asset_min: this.form.assetMin,
        asset_max: this.form.assetMax,
        fi_types: this.form.fiType,
        geography: this.form.geography,
        product_id: this.productId,
        theme_id: this.themeId
      }
      return this.$http
        .get(
          'api/performographics',
          { params: data },
          {
            handleErrors: true
          }
        )
        .then(res => {
          this.filterResult = res.data.total
          this.totalAmount = res.data.total_price
          this.encryptedPrice = res.data.encrypted_price
          this.tokens = res.data.tokens
          this.priceWithoutDiscounts = res.data.price_without_discounts
          this.$wait.end('applyingFilter')
        })
    },
    skipFilter() {
      return [
        this.$route.query.asset_min,
        this.$route.query.asset_max,
        this.$route.query.fi_type
      ].every(val => val === undefined)
    },
    setFilterData() {
      this.form.assetMin = this.$route.query.asset_min
      this.form.assetMax = this.$route.query.asset_max
      this.form.fiType = this.fiTypes[this.$route.query.fi_type] || [
        'bank',
        'cu'
      ]
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.blockUI = true
      this.submitFilter()
    },
    onReset(evt) {
      evt.preventDefault()
      this.form.assetMin = null
      this.form.assetMax = null
      this.form.fiType = ['bank', 'cu']
      this.form.geography = []
      this.submitFilter().then(() => {
        this.blockUI = false
      })
    },
    handlePurchase: function() {
      this.currentUserEmail
        ? (this.showModal = true)
        : (this.showAuthModal = true)
    },
    closeModal: function() {
      this.orderStage = 'summary'
      this.showModal = false
      this.updatePriceDetails()
    },
    updatePriceDetails() {
      this.$wait.start('updatingPriceDetails')

      let data = {
        asset_min: this.form.assetMin,
        asset_max: this.form.assetMax,
        fi_types: this.form.fiType,
        geography: this.form.geography,
        product_id: this.productId,
        theme_id: this.themeId
      }

      return this.$http
        .get(
          'api/performographics',
          { params: data },
          {
            handleErrors: true
          }
        )
        .then(res => {
          this.filterResult = res.data.total
          this.totalAmount = res.data.total_price
          this.encryptedPrice = res.data.encrypted_price
          this.tokens = res.data.tokens
          this.priceWithoutDiscounts = res.data.price_without_discounts
          this.$wait.end('updatingPriceDetails')
        })
    },
    createOrder: function() {
      this.loading = true
      this.$http
        .post(
          '/api/checkouts',
          {
            record_count: this.resultCount,
            amount: this.totalAmount,
            encrypted_price: this.encryptedPrice,
            product_id: this.productId,
            theme_id: this.themeId,
            filters: {
              asset_min: this.form.assetMin,
              asset_max: this.form.assetMax,
              fi_types: this.form.fiType,
              geography: this.form.geography
            }
          },
          {
            handleErrors: true
          }
        )
        .then(res => {
          this.loading = false
          if (res.data.ordered) {
            this.downloadUrl = res.data.url
            this.orderStage = 'confirmation'
          } else {
            this.clientToken = res.data.client_token
            this.orderId = res.data.order_id
            this.orderStage = 'payment'
          }
        })
    },
    payForOrder: function(payload) {
      let data = new window.FormData()
      data.append('order_id', this.orderId)
      data.append('payment_method_nonce', payload.nonce)

      this.loading = true
      this.$http
        .post('/api/checkouts/complete', data, {
          handleErrors: true
        })
        .then(res => {
          this.loading = false
          if (res.data.order_success) {
            this.downloadUrl = res.data.url
            this.orderStage = 'confirmation'
            this.tokens = res.data.tokens
          } else {
            this.closeModal()
            this.$toasted.show(BTErrorMessage, {
              icon: 'chain-broken',
              type: 'error'
            })
          }
        })
    },
    loginSuccess() {
      this.closeAuthModal()
      this.blockUI = true
      this.submitFilter()
    },
    closeAuthModal() {
      this.showAuthModal = false
    }
  },
  created() {
    this.$store.dispatch('marketShareReport/getStatesList')
  }
}
</script>

<style lang="scss" scoped>
.bottom-deck {
  height: 16rem;
}
.product-report {
  .purchase-card {
    .card-body {
      &.alert {
        padding: 1em 0;
      }
      padding: 0;
      button {
        color: white;
        font-size: 1.3em;
      }
      .title-icon {
        color: gray;
      }
      .col-5 {
        color: initial;
      }
    }
  }

  .multiselect__input {
    font-size: 14px;
  }
  .firmographics-filter {
    .total_asset_label {
      margin-top: 8px;
    }
    .geography_label {
      margin-top: 3px;
    }
  }

  .purchase-link {
    color: #20a8d8;

    &:hover {
      color: #167495;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .table-holder {
    height: 30rem;
  }

  .purchase-modal {
    .list-group-item {
      border: none;
      padding: 0.5rem 1.25rem;
    }
  }

  .title-icon {
    padding-right: 0.5rem;
    vertical-align: text-bottom;
  }

  .header-divide {
    margin: 0.5rem 0;
  }

  .page-sub-title,
  .table-caption {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .inline-display {
      display: inline-block;
      .fa-spinner {
        font-size: 1.5em;
      }
    }

    .caption-content {
      width: 80%;
      padding-right: 1rem;
    }
  }
}
.filter-help {
  font-size: 0.8rem;
  margin-left: 1.7rem;
}

.loading-container {
  position: fixed;
}
.price-box {
  padding: 1rem 0 0 1rem;
  width: 16rem;

  table {
    width: 16rem;
  }

  .strong {
    font-weight: bolder;
  }
}

.hide-with-space {
  visibility: hidden;
}
</style>
